@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,700;0,900;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Overpass&display=swap');

.Container {
  position: relative;
  min-height: 500px;
  max-height: 800px;
  overflow: hidden;
  margin-bottom: 300px;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color: red;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* width: 100%;
  height: 100%; */
  /* display: flex; */
  justify-content: left;
  /* align-items: center; */
  padding-left: 5%;
}

.Content h1 {
  color: red;
  font-size: 150px;
  line-height: 0.8;
  font-family: 'Rubik', sans-serif;
  margin-top: 20px;
  margin-bottom: 5px;
  /* z-index: 1; */
  text-shadow: 5px 5px whitesmoke;
  text-align: left;
  /* -webkit-text-stroke: 5px black; */
}

.Content h3 {
  font-size: 48px;
  margin-top: 5px;
  margin-left: 0;
  margin-right: 0;
  font-family: 'Overpass', sans-serif;
  margin-bottom: 2px;
  z-index: 2;
  text-shadow: 1px 1px whitesmoke;
  text-align: left;
  /* -webkit-text-stroke: 0.1px black; */
}

.Content p {
  line-height: 1;
  font-size: 35px;
  margin-top: 2px;
  font-family: 'Overpass', sans-serif;
  margin-bottom: 2px;
  z-index: 2;
  text-shadow: 1px 1px whitesmoke;
  text-align: left;
  /* -webkit-text-stroke: 0.3px black; */
}

@media all and (max-width: 1300px) and (min-width: 920px) {
  .Video {
    width: 135%;
    height: 100%;
    object-position: -250px;
  }
}

@media all and (max-width: 920px) and (min-width: 500px) {
  .Video {
    width: 150%;
    height: 100%;
    object-position: -215px;
  }
}

@media only screen and (max-device-width: 900px) {
  .Video {
    width: 100%;
    height: 100%;
  }

  .Content h1 {
    color: red;
    font-size: 28px;
    line-height: 0.9;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 5px;
    /* z-index: 1; */
    text-shadow: 0.5px 0.5px whitesmoke;
    text-align: left;
    /* -webkit-text-stroke: 5px black; */
  }

  .Content h3 {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0;
    margin-right: 0;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 2px;
    z-index: 2;
    text-shadow: 1px 1px whitesmoke;
    text-align: left;
    /* -webkit-text-stroke: 0.1px black; */
  }

  .Content p {
    line-height: 1;
    font-size: 12px;
    margin-top: 2px;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 2px;
    z-index: 2;
    text-shadow: 1px 1px whitesmoke;
    text-align: left;
    /* -webkit-text-stroke: 0.3px black; */
  }
}
