@import url('https://fonts.googleapis.com/css2?family=Overpass&display=swap');

.contact {
  height: 500px;
}

.title {
  font-size: 50px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
}

.centered,
.button,
.button .qube .front::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.centered,
.buttonTwo,
.buttonTwo .qubeTwo .frontTwo::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.full,
.button .qube .front,
.button .qube .back,
.button .qube .front::before {
  width: 100%;
  height: 100%;
}

.full,
.buttonTwo .qubeTwo .frontTwo,
.buttonTwo .qubeTwo .backTwo,
.buttonTwo .qubeTwo .frontTwo::before {
  width: 100%;
  height: 100%;
}

.button {
  width: 500px;
  height: 90px;
  margin-top: 30px;
  perspective: 1800px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonTwo {
  width: 500px;
  height: 200px;
  margin-top: 80px;
  perspective: 1800px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button .qube {
  position: relative;
  height: 60px;
  width: 1185px;
  perspective: 1800px;
}

.buttonTwo .qubeTwo {
  position: relative;
  height: 60px;
  width: 1185px;
  perspective: 1800px;
}

.button .qube .front,
.button .qube .back {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  transition: transform 0.3s ease;
  position: absolute;
  color: white;
  line-height: 60px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
  font-size: 35px;
}

.buttonTwo .qubeTwo .frontTwo,
.buttonTwo .qubeTwo .backTwo {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  transition: transform 0.3s ease;
  position: absolute;
  color: white;
  line-height: 60px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
  font-size: 35px;
}

.button .qube .front {
  background-color: #191919;
}

.buttonTwo .qubeTwo .frontTwo {
  background-color: #191919;
}

.buttonTwo .qubeTwo .frontTwo::before {
  content: '';
  display: block;
  z-index: -1;
}

.buttonTwo .qubeTwo .frontTwo::before {
  content: '';
  display: block;
  z-index: -1;
}

.button .qube .front::after {
  background: #fff;
  content: '';
  height: 155px;
  left: -75px;
  opacity: 0.4;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}

.buttonTwo .qubeTwo .frontTwo::after {
  background: #fff;
  content: '';
  height: 155px;
  left: -75px;
  opacity: 0.4;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}

.button .qube .back {
  background-color: #0e3386;
  color: white;
  transform: rotateX(0deg) translateZ(60px);
}

.buttonTwo .qubeTwo .backTwo {
  background-color: #0e3386;
  color: white;
  transform: rotateX(0deg) translateZ(60px);
}

.button .qube:hover .front {
  transform: rotateX(0deg) translateZ(30px);
}

.buttonTwo .qubeTwo:hover .frontTwo {
  transform: rotateX(0deg) translateZ(30px);
}

.buttonTwo .qubeTwo:hover .backTwo {
  transform: rotateX(90deg) translateZ(30px);
}

.button .qube:hover .back {
  transform: rotateX(90deg) translateZ(30px);
}

.buttonTwo .qubeTwo:hover .backTwo {
  transform: rotateX(90deg) translateZ(30px);
}

.button:active .front {
  border-radius: 0;
  background-color: transparent;
  color: #191919;
}

.buttonTwo:active .frontTwo {
  border-radius: 0;
  background-color: transparent;
  color: #191919;
}

.button:active .front::before {
  background-color: black;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.buttonTwo:active .frontTwo::before {
  background-color: black;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.button:active .front::after {
  left: 120%;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.buttonTwo:active .frontTwo::after {
  left: 120%;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media only screen and (max-device-width: 900px) {
  .title {
    font-size: 30px;
    text-align: center;
    font-family: 'Overpass', sans-serif;
  }

  .button {
    width: 300px;
    height: 90px;
    margin-top: 30px;
    perspective: 1800px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonTwo {
    width: 300px;
    height: 200px;
    margin-top: 80px;
    perspective: 1800px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button .qube .front,
  .button .qube .back {
    /* backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateX(-90deg);
    transition: transform 0.3s ease;
    position: absolute;
    color: white;
    line-height: 60px;
    text-align: center;
    font-family: 'Overpass', sans-serif; */
    font-size: 25px;
  }

  .buttonTwo .qubeTwo .frontTwo,
  .buttonTwo .qubeTwo .backTwo {
    /* backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateX(-90deg);
    transition: transform 0.3s ease;
    position: absolute;
    color: white;
    line-height: 60px;
    text-align: center;
    font-family: 'Overpass', sans-serif; */
    font-size: 25px;
  }
}
