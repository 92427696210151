@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,700;0,900;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.BackgroundVideo_Container__N7dbd {
  position: relative;
  min-height: 500px;
  max-height: 800px;
  overflow: hidden;
  margin-bottom: 300px;
}

/* Style the video: 100% width and height to cover the entire window */
.BackgroundVideo_Video__3TEiA {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.BackgroundVideo_Content__18_T9 {
  position: absolute;
  top: 0;
  color: red;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* width: 100%;
  height: 100%; */
  /* display: flex; */
  justify-content: left;
  /* align-items: center; */
  padding-left: 5%;
}

.BackgroundVideo_Content__18_T9 h1 {
  color: red;
  font-size: 150px;
  line-height: 0.8;
  font-family: 'Rubik', sans-serif;
  margin-top: 20px;
  margin-bottom: 5px;
  /* z-index: 1; */
  text-shadow: 5px 5px whitesmoke;
  text-align: left;
  /* -webkit-text-stroke: 5px black; */
}

.BackgroundVideo_Content__18_T9 h3 {
  font-size: 48px;
  margin-top: 5px;
  margin-left: 0;
  margin-right: 0;
  font-family: 'Overpass', sans-serif;
  margin-bottom: 2px;
  z-index: 2;
  text-shadow: 1px 1px whitesmoke;
  text-align: left;
  /* -webkit-text-stroke: 0.1px black; */
}

.BackgroundVideo_Content__18_T9 p {
  line-height: 1;
  font-size: 35px;
  margin-top: 2px;
  font-family: 'Overpass', sans-serif;
  margin-bottom: 2px;
  z-index: 2;
  text-shadow: 1px 1px whitesmoke;
  text-align: left;
  /* -webkit-text-stroke: 0.3px black; */
}

@media all and (max-width: 1300px) and (min-width: 920px) {
  .BackgroundVideo_Video__3TEiA {
    width: 135%;
    height: 100%;
    object-position: -250px;
  }
}

@media all and (max-width: 920px) and (min-width: 500px) {
  .BackgroundVideo_Video__3TEiA {
    width: 150%;
    height: 100%;
    object-position: -215px;
  }
}

@media only screen and (max-device-width: 900px) {
  .BackgroundVideo_Video__3TEiA {
    width: 100%;
    height: 100%;
  }

  .BackgroundVideo_Content__18_T9 h1 {
    color: red;
    font-size: 28px;
    line-height: 0.9;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 5px;
    /* z-index: 1; */
    text-shadow: 0.5px 0.5px whitesmoke;
    text-align: left;
    /* -webkit-text-stroke: 5px black; */
  }

  .BackgroundVideo_Content__18_T9 h3 {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0;
    margin-right: 0;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 2px;
    z-index: 2;
    text-shadow: 1px 1px whitesmoke;
    text-align: left;
    /* -webkit-text-stroke: 0.1px black; */
  }

  .BackgroundVideo_Content__18_T9 p {
    line-height: 1;
    font-size: 12px;
    margin-top: 2px;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 2px;
    z-index: 2;
    text-shadow: 1px 1px whitesmoke;
    text-align: left;
    /* -webkit-text-stroke: 0.3px black; */
  }
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
    /* moz-transform: rotateY(0deg); //Firefox
  ms-transform: rotateY(0deg); //Microsoft Browsers */
  }
  to {
    transform: rotateY(360deg);
    /* moz-transform: rotateY(360deg); //Firefox
  ms-transform: rotateY(360deg); //Microsoft Browsers */
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}

.about {
  position: relative;
  min-height: 300px;
  max-height: 1000px;
  overflow: hidden;
  margin-bottom: 50px;
}

.about-title {
  font-size: 50px;
  text-align: center;
  font-family: "Overpass", sans-serif;
  margin-top: 100px;
}

.headshot {
  /* position: absolute; */
  max-width: 15%;
}

/* .headshot:hover {
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  -webkit-animation-name: spin;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-duration: 2s;
} */

.about h3 {
  font-family: "Overpass", sans-serif;
  font-size: 25px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

/* h3:hover {
  color: #0e3386;
} */

.social-links {
  display: flex;
  justify-content: space-between;
  width: 25%;
  margin: auto;
  padding-bottom: 15px;
  text-decoration: none;
}

.social-links i {
  color: black;
  font-size: 5.5em;
  padding: 8px;
  text-decoration: none;
}

/* i:hover {
  color: #0e3386;
  text-decoration: none;
} */

/* i:before,
i:after {
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  z-index: -1;
} */

i:hover:before,
i:hover:after {
  color: #0e3386;
  border-color: transparent;
  -webkit-filter: blur(1px);
  filter: blur(1px);
  transform: scale(4);
  transition: 1000ms transform ease, 2100ms blur ease, 900ms border-color ease;
}
i:hover:after {
  transition-delay: 100ms;
}

.link {
  margin: 0;
  color: black;
  text-decoration: none;
  font-family: "Overpass", sans-serif;
  font-size: 20px;
}

a:link {
  text-decoration: none;
}
@media only screen and (max-device-width: 900px) {
  .about-title {
    font-size: 30px;
    text-align: center;
    font-family: "Overpass", sans-serif;
    margin-top: 50px;
  }

  .headshot {
    /* position: absolute; */
    max-width: 25%;
  }

  .about h3 {
    font-family: "Overpass", sans-serif;
    font-size: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .social-links i {
    color: black;
    font-size: 2.5em;
    padding: 8px;
    text-decoration: none;
  }

  .link {
    margin: 0;
    color: black;
    text-decoration: none;
    font-family: "Overpass", sans-serif;
    font-size: 12px;
    object-position: -100px;
  }

  .social-links {
    display: flex;
    justify-content: space-between;
    width: 42%;
    margin: auto;
    padding-bottom: 15px;
    text-decoration: none;
  }

  .about {
    position: relative;
    min-height: 300px;
    max-height: 1000px;
    overflow: hidden;
    margin-bottom: 10px;
  }
}

.projects {
  position: relative;
  min-height: 300px;
  max-height: 2200px;
  overflow: hidden;
  margin-bottom: 100px;
}

.title h1 {
  font-size: 50px;
  text-align: center;
  font-family: "Overpass", sans-serif;
  padding-bottom: 50px;
}

.wrapper {
  /* position: relative; */
  /* width: 100px;
  height: 250px; */
  margin-left: auto;
  margin-right: auto;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  max-width: 650px;
  /* max-height: 3000px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  max-height: 650px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px; */
}

.card {
  flex: 3 1;
  flex-basis: 300px;
  flex-grow: 0;
  width: 300px;
  height: 440px;
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  overflow: hidden;
  margin-bottom: 20px;
  /* position: relative; */
}

.card img {
  width: 100%;
  height: 100%;
  /* object-fit: fill; */
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  /* overflow: hidden; */
}

.descriptions {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  -webkit-clip-path: circle(0% at 100% 100%);
          clip-path: circle(0% at 100% 100%);
}

.card:hover .descriptions {
  left: 0px;
  transition: all 0.7s ease-in-out;
  -webkit-clip-path: circle(75%);
          clip-path: circle(75%);
}

.card:hover {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(0.97);
}

.card:hover img {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  transform: scale(1.6) rotate(20deg);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.card h1 {
  color: #ff3838;
  letter-spacing: 1px;
  margin: 0px;
}
.card p {
  line-height: 24px;
  font-family: "Overpass", sans-serif;
  font-size: 20px;
  /* height: 70%; */
  margin-top: 20px;
}

.wrapper i {
  color: black;
  font-size: 3em;
  /* padding-top: 50px; */
  text-decoration: none;
}

.wrapper h4 {
  font-size: 20px;
  margin: 0;
}

.wrapperTwo i {
  color: black;
  font-size: 3em;
  /* padding-top: 50px; */
  text-decoration: none;
}

.wrapperTwo h4 {
  font-size: 20px;
  margin: 0;
}

@media only screen and (max-device-width: 900px) {
  .title h1 {
    font-size: 30px;
    text-align: center;
    font-family: "Overpass", sans-serif;
    padding-bottom: 10px;
  }

  .wrapper {
    /* position: relative; */
    /* width: 100px;
    height: 250px; */
    margin-left: auto;
    margin-right: auto;
    /* transform: translate(-50%, -50%); */
    width: 100%;
    max-width: 650px;
    max-height: 4000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .projects {
    position: relative;
    min-height: 300px;
    max-height: 4000px;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .card {
    flex: 3 1;
    flex-basis: 300px;
    flex-grow: 0;
    width: 300px;
    height: 440px;
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    /* position: relative; */
  }
}

.contact {
  height: 500px;
}

.title {
  font-size: 50px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
}

.centered,
.button,
.button .qube .front::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.centered,
.buttonTwo,
.buttonTwo .qubeTwo .frontTwo::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.full,
.button .qube .front,
.button .qube .back,
.button .qube .front::before {
  width: 100%;
  height: 100%;
}

.full,
.buttonTwo .qubeTwo .frontTwo,
.buttonTwo .qubeTwo .backTwo,
.buttonTwo .qubeTwo .frontTwo::before {
  width: 100%;
  height: 100%;
}

.button {
  width: 500px;
  height: 90px;
  margin-top: 30px;
  perspective: 1800px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonTwo {
  width: 500px;
  height: 200px;
  margin-top: 80px;
  perspective: 1800px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button .qube {
  position: relative;
  height: 60px;
  width: 1185px;
  perspective: 1800px;
}

.buttonTwo .qubeTwo {
  position: relative;
  height: 60px;
  width: 1185px;
  perspective: 1800px;
}

.button .qube .front,
.button .qube .back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  transition: transform 0.3s ease;
  position: absolute;
  color: white;
  line-height: 60px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
  font-size: 35px;
}

.buttonTwo .qubeTwo .frontTwo,
.buttonTwo .qubeTwo .backTwo {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  transition: transform 0.3s ease;
  position: absolute;
  color: white;
  line-height: 60px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
  font-size: 35px;
}

.button .qube .front {
  background-color: #191919;
}

.buttonTwo .qubeTwo .frontTwo {
  background-color: #191919;
}

.buttonTwo .qubeTwo .frontTwo::before {
  content: '';
  display: block;
  z-index: -1;
}

.buttonTwo .qubeTwo .frontTwo::before {
  content: '';
  display: block;
  z-index: -1;
}

.button .qube .front::after {
  background: #fff;
  content: '';
  height: 155px;
  left: -75px;
  opacity: 0.4;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}

.buttonTwo .qubeTwo .frontTwo::after {
  background: #fff;
  content: '';
  height: 155px;
  left: -75px;
  opacity: 0.4;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}

.button .qube .back {
  background-color: #0e3386;
  color: white;
  transform: rotateX(0deg) translateZ(60px);
}

.buttonTwo .qubeTwo .backTwo {
  background-color: #0e3386;
  color: white;
  transform: rotateX(0deg) translateZ(60px);
}

.button .qube:hover .front {
  transform: rotateX(0deg) translateZ(30px);
}

.buttonTwo .qubeTwo:hover .frontTwo {
  transform: rotateX(0deg) translateZ(30px);
}

.buttonTwo .qubeTwo:hover .backTwo {
  transform: rotateX(90deg) translateZ(30px);
}

.button .qube:hover .back {
  transform: rotateX(90deg) translateZ(30px);
}

.buttonTwo .qubeTwo:hover .backTwo {
  transform: rotateX(90deg) translateZ(30px);
}

.button:active .front {
  border-radius: 0;
  background-color: transparent;
  color: #191919;
}

.buttonTwo:active .frontTwo {
  border-radius: 0;
  background-color: transparent;
  color: #191919;
}

.button:active .front::before {
  background-color: black;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.buttonTwo:active .frontTwo::before {
  background-color: black;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.button:active .front::after {
  left: 120%;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.buttonTwo:active .frontTwo::after {
  left: 120%;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media only screen and (max-device-width: 900px) {
  .title {
    font-size: 30px;
    text-align: center;
    font-family: 'Overpass', sans-serif;
  }

  .button {
    width: 300px;
    height: 90px;
    margin-top: 30px;
    perspective: 1800px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonTwo {
    width: 300px;
    height: 200px;
    margin-top: 80px;
    perspective: 1800px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button .qube .front,
  .button .qube .back {
    /* backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateX(-90deg);
    transition: transform 0.3s ease;
    position: absolute;
    color: white;
    line-height: 60px;
    text-align: center;
    font-family: 'Overpass', sans-serif; */
    font-size: 25px;
  }

  .buttonTwo .qubeTwo .frontTwo,
  .buttonTwo .qubeTwo .backTwo {
    /* backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateX(-90deg);
    transition: transform 0.3s ease;
    position: absolute;
    color: white;
    line-height: 60px;
    text-align: center;
    font-family: 'Overpass', sans-serif; */
    font-size: 25px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

